const resource = '/me/'

export interface MeGetResponse {
  award_years: Array<number>
  grand_prix_years: Array<number>
  feature_flags: Record<string, boolean>
  site_settings: Record<string, boolean>
}

export default class MeApi {
  private readonly axios = axiosClient

  get(): Promise<MeGetResponse> {
    try {
      const headers: { [key in string]: string } = {}
      const rawHeaders = useRequestHeaders()

      headers['x-forwarded-for-nuxt'] = rawHeaders['x-forwarded-for-nuxt']

      return this.axios.$get(`${resource}`, {
        headers,
      })
    } catch {
      return this.axios.$get(`${resource}`)
    }
  }
}
